<template>
  <div class="compare-vehicle-title">
    <!-- Mobile only YMMS -->
    <div class="d-lg-none">
      <div class="row mobile-spacer"></div>
      <div class="row mb-0">
        <div class="col-auto link" @click="goToDetail(vehicle)">
          <ymms :vehicle="vehicle" />
        </div>
      </div>
    </div>
    <div class="row mt-0 mt-lg-3">
      <div class="col mx-0 px-0 mx-lg-3">
        <router-link :to="`detail/${vehicle.YMMS_Desc}`">
          <vehicle-image :vehicle="vehicle" class="vehicle-image" imgType="exterior" imgSizeType="Wide" />
        </router-link>
      </div>
    </div>
    <!-- Desktop only YMMS -->
    <div class="row mt-3 d-none d-lg-block">
      <div class="col-auto link" @click="goToDetail(vehicle)">
        <ymms :vehicle="vehicle" :learn-more="true" />
      </div>
    </div>
    <div class="row no-match" v-show="!vehicle.Matched">
      <div class="col-auto d-flex align-items-center"><i class="fas fa-info-circle"></i></div>
      <div class="col">
        <p>This vehicle doesn't match all of your search criteria, but this is the
          closest that could be found. You may
          choose a different vehicle or try a different search.</p>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-lg-auto col-12 pr-3 pr-lg-4 mt-3 mt-lg-1">
        <router-link :to="`/detail/${vehicle.YMMS_Desc}`" custom v-slot="{ navigate }">
          <button @click="navigate" class="btn button large learn-more-link" :class="{ tertiary: vehicle.rank != 1 }"
            role="link">
            learn more<span class="accent">&nbsp;&raquo;</span>
          </button>
        </router-link>
      </div>
      <div class="col pl-4 ml-3 ml-lg-0 pl-lg-2 pt-lg-1 pt-3 pr-lg-4">
        <a :href="vehicle.Build_Url" class="build-and-price-link" :disabled="!vehicle.Build_Url"
          :class="{ disabled: !vehicle.Dealer_Url }">
          <div class="row">
            <div class="col-auto px-0 mr-n2 pt-1"><i class="fas fa-cart-arrow-down"></i></div>
            <div class="col">Build
              &
              Price&nbsp;<span>&raquo;</span></div>
          </div>
        </a>
      </div>
      <div class="col pt-3 pt-lg-1">
        <a :href="vehicle.Dealer_Url" class="find-dealer-link" :disabled="!vehicle.Dealer_Url"
          :class="{ disabled: !vehicle.Dealer_Url }">
          <div class="row">
            <div class="col-auto px-0 mr-n2 pt-1"><i class="fas fa-search-location"></i></div>
            <div class="col">Find a Dealer&nbsp;<span>&raquo;</span></div>
          </div>
        </a>
      </div>
    </div>
    <div class="row my-4 pr-2">
      <div class="col" v-show="vehicle.Matched">
        <match-score :vehicle="vehicle" />
      </div>
    </div>
  </div>
</template>
<script>
const VehicleImage = () => import("Components/VehicleImage");
const ymms = () => import("Components/YMMS");
const MatchScore = () => import("Components/Compare/MatchScore");

export default {
  props: {
    vehicle: Object,
  },
  methods: {
    goToDetail(vehicle) {
      this.$router.push({
        path: `detail/${vehicle.YMMS_Desc}`,
      });
    },
  },
  components: {
    MatchScore,
    VehicleImage,
    ymms,
  },
};
</script>
<style lang="scss">
.compare-vehicle-title {
  @include media-breakpoint-down(md) {
    margin-left: 11px;
  }

  img.vehicle-image {
    opacity: 1;
    width: 100%;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;

    @include media-breakpoint-up(lg) {
      border: $secondary-font-color-dark 3px solid;
    }

    @include media-breakpoint-down(md) {
      margin-left: -12px;
    }
  }

  a img:hover {
    opacity: 0.5;
  }

  .link:hover {
    cursor: pointer;

    span:not(.series) {
      border-bottom: 1px solid $primary-font-color-light;
    }
  }

  a {
    text-decoration: underline;
    font-size: 0.75rem;
  }

  .mobile-spacer {
    height: 20px;
    background-color: #edf2f5;
  }

  @include media-breakpoint-up(lg) {
    .series {
      display: block !important;
    }
  }
}

button.learn-more-link {
  min-height: 30px;
  font-size: 0.875rem !important;
  max-width: 100% !important;
  width: 100%;
}

.no-match {
  background-color: $light-bg-color;
  margin: 5px 0 10px 0;
  padding-top: 5px;
  padding-bottom: 5px;

  i {
    color: $secondary-button-bg-color;
    font-size: 1.25rem;
  }

  p {
    font-size: 0.75rem;
    color: #333333;
    margin-bottom: 0;
  }
}
</style>
